import { template as template_6db2b8f618b44fd4a3fd207d7abbf9c7 } from "@ember/template-compiler";
const FKText = template_6db2b8f618b44fd4a3fd207d7abbf9c7(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
